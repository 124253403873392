<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Импорт студентов
          </template>
          <template v-slot:toolbar>
            
          </template>
          <template v-slot:body>
            <b-form>
              <b-form-group>
                <template v-slot:label>
                  {{ $t("studentGroups.list.name") }}
                </template>
                <b-form-input
                    v-model="form.groupName"
                    type="email"
                    :state="formStates.groupName"
                    required
                ></b-form-input>
                <div class="invalid-feedback">{{ formSaveError.groupName }}</div>
              </b-form-group>
              <b-form-group>
                <template v-slot:label>
                  {{ $t("studentGroups.list.level") }}
                </template>
                <b-form-select
                    v-model="form.level"
                    :options="levels"
                    :state="formStates.level"
                    required
                ></b-form-select>
                <div class="invalid-feedback">{{ formSaveError.level }}</div>
              </b-form-group>
              <b-form-group>
                <template v-slot:label>
                  {{ $t("studentGroups.list.classroomTeacher") }}
                </template>
                <b-form-select
                    v-model="form.classroomTeacherId"
                    :options="teachers"
                    required
                    :state="formStates.classroomTeacherId"
                ></b-form-select>
                <div class="invalid-feedback">{{ formSaveError.classroomTeacherId }}</div>
              </b-form-group>
              <b-form-group>
                <template v-slot:label>
                  {{ $t("studentGroups.list.shift") }}
                </template>
                <b-form-select
                    v-model="form.shift"
                    :state="formStates.shift"
                    :options="shifts"
                    required
                ></b-form-select>
                <div class="invalid-feedback">{{ formSaveError.shift }}</div>
              </b-form-group>
              
              
            </b-form>
            
            <textarea @change="test" v-model="innList" style="border:1px solid black;">
              
            </textarea>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>ИНН</th>
                <th>Фамилия</th>
                <th>Имя</th>
                <th>Отчество</th>
                <th>Родитель</th>
                <th>Номер телефона</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="student in students" :key="student.inn" :class="checkInn(student)">
                <td>{{student.inn}}</td>
                <td>{{student.firstName}}</td>
                <td>{{student.name}}</td>
                <td>{{student.patronymic}}</td>
                <td>{{student.parentName}}</td>
                <td>{{student.parentPhone}}</td>
              </tr>
              </tbody>
            </table>
            <div style="text-align: right">
              <b-button type="submit" @click="save" variant="primary" id="save-data-button">{{
                  $t("common.save")
                }}
              </b-button>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
.table-danger td{
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
export default {
  name: "importStudent",
  components: {
    KTPortlet
  },
  data() {
    return {
      students:[],
      innList:'',
      form:{},
      formStates: {},
      formSaveError: {},
      levels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      teachers: [],
      hasErrors: false,
      educationYears: [{ text: "2023-2024", value: 2023 }, {text: "2022-2023", value: 2022} ],
      shifts: [{text: "Первая", value: 1}, {text: "Вторая", value: 2}, {text: "Третья", value: 3}],
    };
  },
  mounted() {
    ApiService.querySecured("dictionaries/teachersLoggedUserSchool", {}).then(({data}) => {
      this.teachers = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
  },
  methods: {
    checkInn(student){
      return student.inn.length !== 14 ? "table-danger" : "";
    },
    save(evt){
      let submitButton = document.getElementById('save-data-button');
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");

      evt.preventDefault();
      let $this = this;
      this.formStates = {};
      this.formSaveError = {};
      this.form.students = this.students;
      ApiService.postSecured("import/importStudentJson", this.form)
          .then(function () {
            $this.$router.push({name: "schoolAdminStudentGroups"});
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.formSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.formStates[i] = false;
              }
            }
          })
          .finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
    },
    test(){
      let rawRows = this.innList.split("\n");
      let headersArray = [
          "inn",
          "firstName",
          "name",
          "patronymic",
          "parentName",
          "parentPhone",
      ];
      this.students = [];
      rawRows.forEach((rawRow) => {
        let rowObject = {};
        let values = rawRow.split("\t");
        headersArray.forEach((header, idx) => {
          rowObject[header] = values[idx];
        });

        this.students.push(rowObject);
      })
    }
  }
};
</script>
